@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-fadeIn {
  animation: fadeIn 0.3s ease-out;
}

.animate-slideIn {
  animation: slideIn 0.3s ease-out;
}

.animate-scaleIn {
  animation: scaleIn 0.3s ease-out;
}

/* Stagger children animations */
.stagger-animate > * {
  opacity: 0;
  animation: fadeIn 0.3s ease-out forwards;
}

.stagger-animate > *:nth-child(1) { animation-delay: 0.1s; }
.stagger-animate > *:nth-child(2) { animation-delay: 0.2s; }
.stagger-animate > *:nth-child(3) { animation-delay: 0.3s; }
.stagger-animate > *:nth-child(4) { animation-delay: 0.4s; }
.stagger-animate > *:nth-child(5) { animation-delay: 0.5s; }
